body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.broken {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  padding-bottom: 50px;
}

.page-title {
  padding-bottom: 20px;
}

.justify-content-xs-center.row > * {
  padding: 20px;
}

.vid-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.vid-container video {
  display: block;
  margin: 0 auto;
}

.profile > * {
  padding: 20px;
  display: block;
  margin: 0 auto;
}

/*@media screen and (max-width: 550px) {
  .vid-container {
    width:  100vw
  }
}*/